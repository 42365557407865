import React, { useState, useEffect, Fragment } from "react"
import { Link, navigate } from "gatsby"

import { Section, BookSection, Book, BookTitle, BookSubtitle } from "src/components/styled"
import BuilderIcon from "src/svg/icon/Builder"
import { Select, Checkbox, Button, Bottom } from "src/components/shop"
import { IFormula, IWorkshop, ISimpleEvent, IBooking, IPresent } from "src/types"
import { formatEventDate } from "src/helpers/date"
import functions, { handleError } from "src/helpers/functions"
import { useCart } from "src/components/cart"
import { formatMoney } from "src/helpers/text"
import { MAIL_PRICE } from "src/constants"

export enum Action {
  offer = "offer",
  book = "book",
}

const ACTION_LABELS: Record<Action, string> = {
  [Action.offer]: "Offrir",
  [Action.book]: "Réserver",
}

interface Props {
  action: Action
  workshops: IWorkshop[]
  name?: string
}

const BookForm: React.FC<Props> = ({ action, workshops, name = "Atelier Chutes Libres", children }) => {
  const [dates, setDates] = useState<ISimpleEvent[]>()
  const [workshop, setWorkshop] = useState<IWorkshop>(workshops.length === 1 ? workshops[0] : null)
  const [formula, setFormula] = useState<IFormula>()
  const [variant, setVariant] = useState("")
  const [date, setDate] = useState<ISimpleEvent>()
  const [mail, setMail] = useState(false)
  const [quantity, setQuantity] = useState(1)
  const { add } = useCart()

  const slug = workshop?.slug

  useEffect(() => {
    if (action === Action.book && slug) {
      functions
        .get<ISimpleEvent[]>("events?type=" + slug)
        .then(setDates)
        .catch(handleError)
    }
  }, [action, slug])

  const getMaxQuantity = (_formula?: IFormula, _date?: ISimpleEvent) => {
    // TODO: we must also deduct what is already in the cart!
    if (action === Action.offer) {
      return 20
    } else {
      if (_formula && _date) {
        return Math.floor(_date.spots / _formula.people)
      }
      return 1
    }
  }

  const datesHaveFormulas = dates?.some((item) => item.formula)

  const handleFormula = ({ currentTarget }: React.ChangeEvent<HTMLSelectElement>) => {
    const [slug, formulaId] = currentTarget.value.split("/")
    const newWorkshop = workshops.find((ws) => ws.slug === slug)
    const newFormula = newWorkshop?.formulas.find(({ id }) => id === formulaId)
    if (datesHaveFormulas || !formula || !newFormula || formula.people !== newFormula.people) {
      setDate(null)
      setQuantity(1)
    } else {
      setQuantity(Math.min(getMaxQuantity(newFormula, date), quantity))
    }
    setWorkshop(newWorkshop)
    setFormula(newFormula)
  }

  const handleDate = ({ currentTarget }: React.ChangeEvent<HTMLSelectElement>) => {
    const data = dates.find(({ id }) => id === currentTarget.value)
    setDate(data)
    setQuantity(Math.min(getMaxQuantity(formula, data), quantity))
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (action === Action.book) {
      const article: IBooking = {
        workshop: {
          name,
          slug,
        },
        type: "booking",
        formula,
        quantity,
        date,
      }
      if (variant) {
        article.variant = variant
      }
      add(article)
    } else {
      const article: IPresent = {
        type: "present",
        formula,
        quantity,
        mail,
      }
      add(article)
    }

    navigate("/panier/")
  }

  const formulasHaveSamePeople =
    workshops.length === 1 && workshops[0].formulas.every((item) => item.people === workshops[0].formulas[0].people)

  const formulaDates =
    dates &&
    (formula || (!datesHaveFormulas && formulasHaveSamePeople)) &&
    dates.filter((item) => (!item.formula || item.formula === formula.id) && getMaxQuantity(formula, item) > 0)

  const price = (formula ? formula.price : 0) + (mail ? MAIL_PRICE : 0)

  const linkToWorkshop = workshop && workshops.length > 1

  return (
    <>
      <BookSection>
        <BuilderIcon />
        <Book>
          <BookTitle>{ACTION_LABELS[action]}</BookTitle>
          <BookSubtitle>un {name.toLowerCase()}</BookSubtitle>
        </Book>
      </BookSection>
      <Section as="form" onSubmit={handleSubmit}>
        <Select
          label="Tarif"
          value={workshop && formula ? `${workshop.slug}/${formula.id}` : ""}
          onChange={handleFormula}
          description={
            <p>
              {formula?.description}
              {formula?.description && linkToWorkshop && <br />}
              {linkToWorkshop && (
                <Link to={`/ateliers/${slug}/`}>
                  Plus d’infos sur <strong>la page de l’{workshop.name.toLowerCase()}</strong>
                </Link>
              )}
            </p>
          }
          required
        >
          <option value="">Choisissez une formule</option>
          {workshops.map(({ slug, formulas }) => (
            <Fragment key={slug}>
              {formulas.map(({ id, name, price }) => (
                <option key={id} value={`${slug}/${id}`}>
                  {formatMoney(price)} • {name}
                </option>
              ))}
            </Fragment>
          ))}
        </Select>
        {action === Action.book && workshop?.variants && (
          <Select
            label="Objet"
            value={variant}
            onChange={({ currentTarget }) => setVariant(currentTarget.value)}
            required
          >
            <option value="">Choisissez un objet</option>
            {workshop.variants.map((variant, index) => (
              <option key={index}>{variant}</option>
            ))}
          </Select>
        )}
        {action === Action.book && formulaDates && (
          <Select label="Date" value={date?.id || ""} onChange={handleDate} required>
            {formulaDates.length > 0 ? (
              <>
                <option value="">Choisissez une date</option>
                {formulaDates.map((item) => (
                  <option key={item.id} value={item.id}>
                    {formatEventDate(item)}
                  </option>
                ))}
              </>
            ) : (
              <option value="">Aucune date disponible</option>
            )}
          </Select>
        )}
        {action === Action.offer && (
          <Checkbox label="Option" checked={mail} onChange={({ currentTarget }) => setMail(currentTarget.checked)}>
            <strong>
              {formatMoney(MAIL_PRICE)} • je choisis la version papier : une jolie carte dans son pochon en tissu
            </strong>
            <br />
            la carte vous sera envoyée par la poste dans un délai 3 à 5 jours ouvrés
          </Checkbox>
        )}
        <Select
          label="Quantité"
          className="small"
          onChange={({ currentTarget }) => setQuantity(Number(currentTarget.value))}
          value={quantity}
          required
        >
          {Array.from({ length: getMaxQuantity(formula, date) }).map((_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </Select>
        <Bottom>
          {children}
          <Button type="submit" disabled={!(formula && (date || action === Action.offer))}>
            {formula ? `${formatMoney(price * quantity)} • ` : ""}ajouter au panier
          </Button>
        </Bottom>
      </Section>
    </>
  )
}

export default BookForm
