import React from "react"
import { graphql } from "gatsby"

import { GatsbyPage } from "src/types"
import Layout from "src/layout"
import { Content } from "src/components/styled"
import BookForm, { Action } from "src/components/BookForm"

const BookPage: GatsbyPage = ({ data }) => {
  return (
    <Layout title="Réserver">
      <Content>
        <BookForm action={Action.book} workshops={data.workshops.nodes} name="Atelier Chutes Libres" />
      </Content>
    </Layout>
  )
}

export default BookPage

export const query = graphql`
  query {
    workshops: allContentfulWorkshop(sort: { fields: [position], order: ASC }) {
      nodes {
        slug
        name
        formulas {
          id: contentful_id
          name
          prefix
          price
          people
          description
        }
        variants
      }
    }
  }
`
